import { TPermission, TRole } from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

type TUserUpdatePermission = {
  permission_id: string;
  is_enabled: boolean;
}

export type TUserUpdatePermissionRequest = {
  permission_ids: Array<TUserUpdatePermission>;
};

export type GetPermissionsByUserResponse = {
  permissions: Array<TPermission>;
};

class PermissionTransport extends BaseTransport {
  async getPermissions(userId: string, withOtherPermissions: boolean = false, options?: Omit<Options, 'json'>): Promise<Array<TPermission>> {
    const response = await this.get<GetPermissionsByUserResponse>(`permission/${userId}`, {
      searchParams: {
        with_other_permissions: withOtherPermissions
      },
      ...options
    });

    return response.permissions
  }

  async updatePermission(userId: string, request: TUserUpdatePermissionRequest, options?: Omit<Options, 'json'>): Promise<Array<TPermission>> {
    const response = await this.put<TUserUpdatePermissionRequest, GetPermissionsByUserResponse>(`permission/${userId}`, request, options);

    return response.permissions;
  }
}

export const permissionTransport = new PermissionTransport();
