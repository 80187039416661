import { Badge, Button, Loader, Separator } from '@/_shared/components/atoms';
import { cn } from '@/_shared/utils';
import { Column } from '@tanstack/react-table';
import { Check, ChevronsUpDown, ListFilter, Plus } from 'lucide-react';
import { ComponentType, ReactNode } from 'react';
import { Command } from './command';
import { Popover } from './popover';

export type TFacetedFilterOption<TValue extends string> = {
  icon?: ComponentType<{ className?: string }>;
  label: string;
  value: TValue;
};

type TFacetedFilterProps<TData, TValue extends string> = {
  buttonClassName?: string;
  buttonIcon?: ReactNode;
  column?: Column<TData, TValue>;
  isLoading?: boolean;
  onResetFilter?: () => void;
  onSelectionUpdate?: (value: Array<TValue>) => void;
  options: Array<TFacetedFilterOption<TValue>>;
  selected?: Set<TValue>;
  showFacets?: boolean;
  title?: string;
};

export function FacetedFilter<TData, TValue extends string>({
  buttonClassName,
  buttonIcon,
  column,
  isLoading,
  onResetFilter = () => onSelectionUpdate?.([]),
  onSelectionUpdate,
  options,
  selected,
  showFacets,
  title,
}: TFacetedFilterProps<TData, TValue>): JSX.Element {
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = selected ?? new Set(column?.getFilterValue() as Array<TValue> | undefined);

  const handleOnSelectionUpdate = () => {
    onSelectionUpdate?.(Array.from(selectedValues));
  };

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          childrenWrapperClassName={cn('justify-start', !selectedValues?.size && 'text-zinc-500/75')}
          className={cn('h-8 border-dashed', buttonClassName)}
          icon={buttonIcon ?? <ListFilter className="mr-2 h-4 w-4" />}
          rightDecoration={
            (isLoading ? <Loader className="ml-2 h-4 w-4 shrink-0 opacity-50" /> : null) ??
            (!selectedValues?.size ? <Plus className="ml-2 h-4 w-4 shrink-0 opacity-50" /> : null)
          }
          size="sm"
          variant="outline"
        >
          {title}

          {selectedValues?.size > 0 && (
            <>
              <Separator className="mx-2 h-4" orientation="vertical" />
              <Badge className="rounded-sm px-1 font-normal lg:hidden" variant="secondary">
                {selectedValues.size}
              </Badge>

              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 1 ? (
                  <Badge className="rounded-sm px-1 font-normal" variant="secondary">
                    {selectedValues.size} выбрано
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => (
                      <Badge className="rounded-sm px-1 font-normal" key={option.value} variant="secondary">
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </Popover.Trigger>

      <Popover.Content align="start" className="w-[300px] p-0">
        <Command.Root>
          <Command.Input placeholder={title} />

          <Command.List>
            <Command.Empty>Ничего не нашли.</Command.Empty>

            <Command.Group>
              {options.map((option) => {
                const facet = facets?.get(option.value);
                const isSelected = selectedValues.has(option.value);

                const handleOnSelect = () => {
                  if (isSelected) {
                    selectedValues.delete(option.value);
                  } else {
                    selectedValues.add(option.value);
                  }

                  handleOnSelectionUpdate();

                  if (column) {
                    const filterValues = Array.from(selectedValues);
                    column.setFilterValue(filterValues.length ? filterValues : undefined);
                  }
                };

                return (
                  <Command.Item key={option.value} onSelect={handleOnSelect}>
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected ? 'text-primary-background bg-primary' : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <Check className="h-4 w-4" />
                    </div>

                    {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}

                    <span>{option.label}</span>

                    {showFacets && facet && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facet}
                      </span>
                    )}
                  </Command.Item>
                );
              })}
            </Command.Group>

            {selectedValues.size > 0 && (
              <>
                <Command.Separator />

                <Command.Group>
                  <Command.Item
                    className="justify-center text-center"
                    onSelect={() => column?.setFilterValue(undefined) ?? onResetFilter?.()}
                  >
                    Сбросить фильтр
                  </Command.Item>
                </Command.Group>
              </>
            )}
          </Command.List>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  );
}
