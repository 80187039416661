import { FormMessage } from '@/_shared/components/organisms/form/form-message';
import { cn } from '@/_shared/utils';
import * as RForm from '@radix-ui/react-form';
import React, { useId, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Label } from './label';

type TFormFieldWrapperProps = React.ComponentPropsWithoutRef<'div'>;

const FormFieldWrapper = (props: TFormFieldWrapperProps) => {
  return <div {...props} />;
};

FormFieldWrapper.displayName = 'FormFieldWrapper';

type TFormFieldProps = React.ComponentPropsWithoutRef<typeof RForm.Field> & {
  controlClassName?: string;
  errorMessage?: string;
  errorMessageClassName?: string;
  label?: string;
  labelClassName?: string;
  required?: boolean;
  reverseLabelChildOrder?: boolean;
  useWrapper?: boolean;
};

export const FormField = React.forwardRef<React.ElementRef<typeof RForm.Field>, TFormFieldProps>(
  (
    {
      children,
      className,
      controlClassName,
      errorMessage,
      errorMessageClassName,
      label,
      labelClassName,
      required,
      reverseLabelChildOrder,
      useWrapper,
      ...props
    },
    ref,
  ) => {
    const id = useId();
    const fieldClassName = cn('flex flex-col items-stretch w-full', { 'pb-[24px]': !errorMessage }, className);
    const fieldLabelClassName = cn('mb-2 flex w-full cursor-text items-center', labelClassName);
    const fieldControlClassName = cn('flex-auto', controlClassName);
    const fieldErrorMessageClassName = cn('px-3 pt-1', errorMessageClassName);
    const { t } = useTranslation();

    const parsedErrorMessage = useMemo(() => {
      return typeof errorMessage === 'string' && errorMessage.startsWith('{') && errorMessage.endsWith('}')
        ? JSON.parse(errorMessage)
        : undefined;
    }, [errorMessage]);

    return (
      <RForm.Field asChild className={fieldClassName} ref={ref} {...props}>
        <div>
          {[
            label && !reverseLabelChildOrder ? (
              <RForm.Label asChild className={fieldLabelClassName} key={`${id}-label`}>
                <Label>
                  {label}
                  {required && <span className="text-red-500 ml-1">*</span>}
                </Label>
              </RForm.Label>
            ) : null,

            useWrapper ? (
              <FormFieldWrapper className={fieldControlClassName} key={`${id}-control`}>
                {children}
              </FormFieldWrapper>
            ) : (
              <RForm.Control asChild className={fieldControlClassName} key={`${id}-control`}>
                {children}
              </RForm.Control>
            ),

            label && !!reverseLabelChildOrder ? (
              <RForm.Label asChild className={fieldLabelClassName} key={`${id}-label`}>
                <Label>{label}</Label>
              </RForm.Label>
            ) : null,

            errorMessage ? (
              <FormMessage className={fieldErrorMessageClassName} key={`${id}-error-message`}>
                {errorMessage.startsWith('{') && errorMessage.endsWith('}') ? (
                  <Trans t={t} i18nKey={parsedErrorMessage.key} tOptions={parsedErrorMessage.options} />
                ) : (
                  <Trans t={t} i18nKey={errorMessage} />
                )}
              </FormMessage>
            ) : null,
          ].filter(Boolean)}
        </div>
      </RForm.Field>
    );
  },
);

FormField.displayName = 'FormField';
