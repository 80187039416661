import { FormField, Input } from '@/_shared/components/atoms';
import get from 'lodash.get';
import { ChangeEventHandler, FocusEventHandler, useContext } from 'react';
import { Path, useFormContext } from 'react-hook-form';
import { InputAttributes, NumericFormat } from 'react-number-format';
import { NumericFormatProps } from 'react-number-format/types/types';
import { FormDisabledContext } from './context';

const defaultAllowedDecimalSeparators = ['.', ','];

type TFormNumericFieldProps<T extends Record<string, any>> = {
  allowUnregister?: boolean;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  inputMode?: InputAttributes['inputMode'];
  markRequired?: boolean;
  label?: string;
  name: Path<T>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
} & NumericFormatProps;

export function FormNumericField<T extends Record<string, any>>({
  allowedDecimalSeparators = defaultAllowedDecimalSeparators,
  allowLeadingZeros,
  allowNegative = false,
  allowUnregister,
  className,
  decimalScale = 0,
  decimalSeparator = ',',
  disabled,
  inputClassName,
  inputMode = 'numeric',
  label,
  markRequired,
  name,
  onBlur,
  onChange,
  ...numericFormatProps
}: TFormNumericFieldProps<T>) {
  const disabledContext = useContext(FormDisabledContext);
  const { formState, getValues, register } = useFormContext<T>();
  const field = register(name, {
    disabled,
    onBlur,
    onChange,
    shouldUnregister: allowUnregister,
    setValueAs: (value: unknown) => {
      if (value === '') {
        return null;
      }

      if (typeof value === 'number') {
        return value;
      }

      if (typeof value === 'string') {
        const cleanValue = value?.replace(decimalSeparator, '.')?.replace(/[^0-9-.]/g, '');

        return Number(cleanValue);
      }

      return null;
    },
  });
  const fieldValue = getValues(name);

  // const { field, formState } = useController({ name, shouldUnregister: allowUnregister });
  const isInputDisabled = field.disabled || formState.isSubmitting || formState.isLoading || !!disabledContext;
  const fieldError = get(formState.errors, name);
  const errorMessage = fieldError?.message as string | undefined;

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = async (event) => {
    await field.onBlur(event);

    onBlur?.(event);
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    await field.onChange(event);

    onChange?.(event);
  };

  return (
    <FormField
      className={className}
      errorMessage={errorMessage}
      name={name}
      label={label}
      required={markRequired}
      useWrapper
    >
      <NumericFormat
        allowedDecimalSeparators={allowedDecimalSeparators}
        allowLeadingZeros={allowLeadingZeros}
        allowNegative={allowNegative}
        customInput={Input}
        className={inputClassName}
        decimalScale={decimalScale}
        decimalSeparator={decimalSeparator}
        disabled={isInputDisabled}
        getInputRef={field.ref}
        inputMode={inputMode}
        max={field.max}
        maxLength={field.maxLength}
        min={field.min}
        minLength={field.minLength}
        name={field.name}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        pattern={field.pattern}
        required={field.required}
        thousandsGroupStyle="thousand"
        thousandSeparator=" "
        value={fieldValue}
        {...numericFormatProps}
      />
    </FormField>
  );
}

FormNumericField.displayName = 'FormNumericField';
